<template>
  <dot-loader :color="'#fff'"></dot-loader>
  <p style="color: white">{{ this.route.query }}</p>
</template>

<script>
import {useRoute} from "vue-router";
import BackendService from "@/services/BackendService";
import router from "@/router";

export default {
  name: 'bullhornCustom',
  data() {
    return {
      route: useRoute(),
      token: this.route.params.token
    }
  },
  beforeCreate() {
    if(BackendService.validateToken(this.token)){
      router.push('/login')
    }
  }
}
</script>

<style>

</style>